// @ts-strict-ignore
import { kebabCase } from 'lodash';
import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';
import { captureException, captureMessage } from '@sentry/nextjs';
import config from 'config/config';
import { isUserBot } from 'src/utils/seo';
import { isCategoryAccepted, Category, Cookie } from 'src/utils/cookie';
import { Event, AnonymousEvents, Section, Element } from 'src/constants/footfall';
import { footfallPost, get } from 'src/utils/api';

const isServer = typeof window === 'undefined';
let membershipId: string | null = null;
let deviceId: string | null = null;

type value = string | number | boolean | string[] | number[] | null;
type Data = {
  clickType?: string;
  clickMetadata?: {
    section?: Section;
    element?: Element;
  } | Record<string, value> | string;
  [key: string]: value | Record<string, value>;
};

export async function trackFootfallEvent(eventType: Event, data: Data = {}): Promise<void> {
  if (isServer || isUserBot) return;

  const isAnonymous = !isCategoryAccepted(Category.Analytics);
  if (isAnonymous && !AnonymousEvents.includes(eventType)) return;

  await setMembershipAndDeviceIds(isAnonymous);

  if (!isValidEvent(eventType, data)) {
    captureMessage(`Footfall data no valid ${eventType}`);
    return;
  }

  const extendedData = {
    'device-id': deviceId,
    'event-type': eventType,
    'membership-id': membershipId,
    'referrer': getReferrer(isAnonymous),
    'session-id': getSessionId(isAnonymous),
    'source': getSource(),
    'url': `${window.location.pathname}${window.location.search}`.replace(/\s/g, '%20'), // replace whitespace in URL
    ...convertKeysToKebabCase(data),
  };

  if (config.environment.includes('development')) {
    console.info('Footfall event:', extendedData); // eslint-disable-line no-console
  } else {
    footfallPost(JSON.stringify(extendedData)).catch(captureException);
  }
}

// await trackFootfallEvent before changing href
export async function trackFootfallEventWithHrefDelay(href: string, type: Event, data: Data = {}): Promise<void> {
  await trackFootfallEvent(type, data);
  window.location.href = href;
}

// Some events require certain keys, ideally these would be TS types!
// See for requirements: https://github.com/HealthUnlocked/footfall/blob/master/src/cljc/footfall/events.cljc
function isValidEvent(type: Event, data: Data) {
  switch (type) {
    case 'clicked':
      return Object.prototype.hasOwnProperty.call(data, 'clickType');
    case 'viewed-post':
      return Object.prototype.hasOwnProperty.call(data, 'postId');
    case 'viewed-featured-post':
      return Object.prototype.hasOwnProperty.call(data, 'featuredPostId');
    default:
      return true;
  }
}

function convertKeysToKebabCase(data: Data) {
  const formattedData: Data = {};
  Object.keys(data).forEach((key) => formattedData[kebabCase(key)] = data[key]);
  return formattedData;
}

function getSource() {
  return `Solaris${Cookies.get(Cookie.Branch) || ''}`;
}

function getReferrer(isAnonymous: boolean) { // [CHAOS-131]
  if (isAnonymous) return '';
  if (document.referrer.includes('healthunlocked.com')) {
    const huReferrer = Cookies.get(Cookie.Referrer);
    if (huReferrer || huReferrer === '') {
      return huReferrer;
    }
  }
  return document.referrer;
}

function getSessionId(isAnonymous: boolean): string {
  if (isAnonymous) return '';
  const session = JSON.parse(Cookies.get(Cookie.Footfall) || '{}');
  const sessionId = session.sessionId ? session.sessionId : uuid();
  if (!session.sessionId) {
    Cookies.set(Cookie.Footfall, JSON.stringify({ sessionId }), { expires: 30, path: '/' });
  }
  return sessionId;
}

async function setMembershipAndDeviceIds(isAnonymous: boolean) {
  if (isAnonymous) {
    deviceId = '';
    membershipId = '';
  } else if (!membershipId || !deviceId) {
    try {
      const response = await get('session/info');
      if (response?.data?.meta?.session) {
        const sessionData = response.data.meta.session.split(':');
        if (Array.isArray(sessionData)) {
          deviceId = sessionData[0];
          membershipId = sessionData[1];
        }
      }
    } catch (ex) {
      captureException(ex);
    }
  }
}

export function clearTrackingSession(): void {
  membershipId = null;
  deviceId = null;
}
