// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a user loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"07bf177192ccc27b70b66dc6dd1eb4b9c833b4be"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import sentryConfig from 'config/sentry';
import config from 'config/config';

if (config.environment === 'production') {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: config.environment,
    ignoreErrors: sentryConfig.ignoreErrors,
    // Called for message and error events
    beforeSend(event, hint) {
      const error = hint.originalException;
      // [PROD-2052] If the error isn't coming from Axios, do not send the 'endpoint' custom tag
      if (event.exception?.values?.[0]?.type === 'AxiosError') {
        if (!error) return event;
        const url = (error as AxiosError).config?.url?.replace('https://healthunlocked.com', '');
        if (url === '/footfall' && (error as AxiosError).message.includes('timeout')) return null;
        return {
          ...event,
          tags: { endpoint: `${(error as AxiosError).config?.method?.toUpperCase()} ${url}` },
        };
      } else {
        delete event.tags;
        return event;
      }
    },
  });
}
